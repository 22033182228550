@tailwind base;
@tailwind components;
@tailwind utilities;


/* From SUI */
@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 47.4% 11.2%;
		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 222.2 47.4% 11.2%;
		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--card: 0 0% 100%;
		--card-foreground: 222.2 47.4% 11.2%;
		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;
		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;
		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;
		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;
		--ring: 215 20.2% 65.1%;
		--radius: 0.5rem;

		--progress-foreground: 137 99% 33%;
		--progress-background: 0 100% 100%;
		--progress-active-consultation-foreground: 137 100% 95%; /* #E6FEEC */
		--progress-active-consultation-background: 137 100% 33%; /* #00A930 */

		--site-yellow: 37 89% 95%;
		--site-tertiary: 214 3% 43%;

		--subdued: 210 2% 67%;
		--subdued-inverted: 215 91% 79%;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: calc(2rem - 0.14285714em) 0em 1rem;
		padding: 0em;
	}

	h1 {
		@apply text-2xl;
		font-weight: 400;
		@media (max-width: 768px) {
			@apply text-2xl-sm;
		}
	}

	h2 {
		@apply text-xl;
		font-weight: 400;
		@media (max-width: 768px) {
			@apply text-xl-sm;
		}
	}

	h3 {
		@apply text-lg;
		font-weight: 400;
		@media (max-width: 768px) {
			@apply text-lg-sm;
		}
	}

	h4 {
		@apply text-md;
		font-weight: 500;
	}

	h5 {
		@apply text-base;
		font-weight: 500;
	}
	p {
		@apply text-base;
		margin: 0em 0em 1em;
	}
	p:first-child {
		margin-top: 0em;
	}
	p:last-child {
		margin-bottom: 0em;
	}

	.capitalize {
		text-transform:capitalize;
	}
	a.link {
		@apply underline text-blue-600 hover:text-blue-800 hover:underline;
	}
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
   /* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
  }
}